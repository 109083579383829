@import '@/theme.module.less';

.logo {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: @logoColor;
  padding: 0;
  margin: 0;
  overflow: hidden;
  word-break: keep-all;
  font-size: 20px;
}
