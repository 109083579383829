@import '@/theme.module.less';

.header {
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;

  & > div {
    height: 100%;
  }

  .user {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }

  .action {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    transition: color, opacity 300ms;
    opacity: 1;

    &:hover {
      color: @colorPrimary;
      opacity: 0.5;
    }
  }
}
